import React from "react"
import { navigate } from "gatsby"
import { Clipboard, ShoppingBag, Mail, Users, Book } from '@geist-ui/react-icons'

export default (props) => (
	<div className="bottom-navigation">
      <div onClick={() => navigate('/services')} ><Clipboard size={16} /><p>Services</p></div>
      <div onClick={() => navigate('/about')}><Users size={16}  /><p>About</p></div>
      <div><a href="mailto:nicole@enriched.health?subject=Enriched Inquiry"
           target="blank"
           class="livesite-profile"><Mail size={16} /><p>Contact</p></a></div>
	</div>
  
)