import React from "react"
import { Link } from 'gatsby'
import BottomNavigation from "../components/BottomNavigation"
import Logo from '../../static/assets/enriched emblem.svg'

export default () => (
     <footer className="green">
     <div>
     <img src={Logo} alt="Enriched Logo" />
        <h4><a href="mailto:nicole@enriched.health?subject=Enriched Inquiry" 
        target="blank" class="livesite-contact"  
        data-title="Contact">Contact Us</a></h4>
        <p>© 2023 Enriched, All Rights Reserved</p>
     </div>
     <BottomNavigation />
     </footer>
  
)