import React, { useEffect } from "react"
import { navigate } from 'gatsby'
import Logo from '../components/Logo'
import { User } from '@geist-ui/react-icons'

export default function MenuDropdown () {


  return (
      <>
    <div className="desktop top-nav">
        <div style={{display:'flex', width:'40%', justifyContent:'flex-start'}}>
        <h5 onClick={() => navigate('/services')}>Services</h5>
        <h5 onClick={() => navigate('/about')}>About</h5>
        </div>
        <div style={{display:'flex', width:'20%', justifyContent:'center'}}>
        <Logo />
        </div>
        <div style={{display:'flex', width:'40%', justifyContent:'flex-end'}}>
        <div className="menu-icons">
        </div>
        </div>
    </div>
    <div className="top-logo">
        <Logo />
    </div>
    </>
  )
}