import React from "react"
import { navigate } from 'gatsby'
import Helmet from 'react-helmet'
import MenuNavigation from "../components/Menu"
import Footer from "../components/Footer"
import PropTypes from 'prop-types'
import { GeistProvider, CssBaseline } from '@geist-ui/react'
import { User } from '@geist-ui/react-icons'


class Layout extends React.Component {

 constructor(props){
  super(props);

  this.state = {
    user: false,
    showSignIn: false,
    mode: '',
    menu: false
  }
}
   menuMove(url) {
      this.setState({menu: false});
      navigate(url);
    }


  render() {

    const { children } = this.props

    let r = Math.floor(new Date().getTime() / 1000000)

    return  <>
    <Helmet>
    </Helmet>
    <GeistProvider themeType={this.state.mode}>
    <CssBaseline />
    <header className="site-header green">
        <div className="menu-icons mobile">
        </div>
         <MenuNavigation onClick={() => this.setState({menu: false})} />
        <div className="menu-icons mobile">
        </div>
      </header>
    <div className={`site-wrapper ${this.state.mode}`} onClick={() => this.setState({menu: false})}>
      {children}
      <Footer />
    </div>
    </GeistProvider>
    </>
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

